module.exports = [{
      plugin: require('/zeit/33e2c904/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Subhav Gautam","short_name":"Zubhav","start_url":"/","background_color":"#6997BB","theme_color":"#6997BB","display":"standalone","icon":"src/images/icon.png"},
    },{
      plugin: require('/zeit/33e2c904/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84700552-2"},
    },{
      plugin: require('/zeit/33e2c904/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
